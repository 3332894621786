import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/login/login'
const originalPush = Router.prototype.push
import store from "@/store/store";
import { commonUp } from "@/util/axiosHttp";
//解决点击同一路由报错
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(Router)
const router = new Router({
    mode: 'history',
    routes: [
        // 首页
        {
            path: '/',
            name: 'home',
            component: () => import('../components/home/home'),
            meta: {
                title: "首页",
                auth: false,
                keepAlive: true
            }
        },
        // 登录
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: "登录",
                auth: false,
                keepAlive: true
            }
        },
        //选择身份
        {
            path: '/selectIdentity',
            name: 'selectIdentity',
            component: () => import('../components/selectIdentity/selectIdentity'),
            meta: {
                title: "选择身份",
                auth: false,
                keepAlive: true
            }
        }, //选择身份
        {
            path: '/APPDownload',
            name: 'APPDownload',
            component: () => import('../components/APPDownload/APPDownload'),
            meta: {
                title: "APP下载",
                auth: false,
                keepAlive: true
            }
        },

        // 选择标签
        {
            path: '/userLabel',
            name: 'userLabel',
            component: () => import('../components/selectIdentity/userLabel'),
            meta: {
                title: "选择身份",
                auth: false,
                keepAlive: true
            }
        },
        // 搜索
        {
            path: '/searchInfo',
            name: 'searchInfo',
            component: () => import('../components/searchInfo/searchInfo'),
            meta: {
                title: "搜索",
                auth: false,
                keepAlive: true
            }
        },
        // 高级搜索
        {
            path: '/advancedSearch',
            name: 'advancedSearch',
            component: () => import('../components/advancedSearch/advancedSearch'),
            meta: {
                title: "高级搜索",
                auth: false,
                keepAlive: true
            }
        },
        //活动中心
        {
            path: '/activityCenter',
            name: 'activityCenter',
            component: () => import('../components/activityCenter/activityCenter'),
            meta: {
                title: "活动中心",
                auth: false,
                keepAlive: true
            }
        },
        //限免书单
        {
            path: '/acitvityBook',
            name: 'acitvityBook',
            component: () => import('../components/activityCenter/acitvityBook'),
            meta: {
                title: "限免书单",
                auth: false,
                keepAlive: true
            }
        },
        //活动详情
        {
            path: '/activityInfo',
            name: 'activityInfo',
            component: () => import('../components/activityCenter/activityInfo'),
            meta: {
                title: "活动详情",
                auth: false,
                keepAlive: true
            }
        },
        // 古籍库
        {
            path: "/ancientBooks",
            name: 'ancientBooks',
            component: () => import('../components/ancientBooks/ancientBooks'),
            meta: {
                title: "古籍库",
                auth: false,
                keepAlive: true
            }
        },
        // 知识库
        {
            path: "/knowledgeBooks",
            name: 'knowledgeBooks',
            component: () => import('../components/knowledgeBooks/knowledgeBooks'),
            meta: {
                title: "知识库",
                auth: false,
                keepAlive: true
            }
        },
        // 知识可视化
        {
            path: "/knowImg",
            name: 'knowImg',
            component: () => import('../components/knowledgeInfo/knowImg'),
            meta: {
                title: "知识图谱",
                auth: false,
                keepAlive: true
            }
        },
        //知识库列表
        {
            path: "/knowledgeList",
            name: 'knowledgeList',
            component: () => import('../components/knowledgeList/knowledgeList'),
            meta: {
                title: "知识库列表",
                auth: false,
                keepAlive: true
            }
        },
        //知识库方剂功效列表
        {
            path: "/fjcategoryList",
            name: 'fjcategoryList',
            component: () => import('../components/fjcategoryList/fjcategoryList'),
            meta: {
                title: "知识库列表",
                auth: false,
                keepAlive: true
            }
        },
        //知识库详情
        {
            path: "/knowledgeInfo",
            name: 'knowledgeInfo',
            component: () => import('../components/knowledgeInfo/knowledgeInfo'),
            meta: {
                title: "知识库列表",
                auth: false,
                keepAlive: true
            }
        },
        // 知识库名家内容详情
        {
            path: "/perscrInfo",
            name: 'perscrInfo',
            component: () => import('../components/perscrInfo/perscrInfo'),
            meta: {
                title: "名家详情",
                auth: false,
                keepAlive: true
            }
        },
        // 知识库本草内容详情
        {
            path: "/categoriesInfo",
            name: 'categoriesInfo',
            component: () => import('../components/categoriesInfo/categoriesInfo'),
            meta: {
                title: "本草详情",
                auth: false,
                keepAlive: true
            }
        },
        // 知识库经穴内容详情
        {
            path: "/jxcategoriesInfo",
            name: 'jxcategoriesInfo',
            component: () => import('../components/jxcategoriesInfo/jxcategoriesInfo'),
            meta: {
                title: "经穴详情",
                auth: false,
                keepAlive: true
            }
        },
        // 知识库方剂内容详情
        {
            path: "/fangcategoriesInfo",
            name: 'fangcategoriesInfo',
            component: () => import('../components/fangcategoriesInfo/fangcategoriesInfo'),
            meta: {
                title: "方剂详情",
                auth: false,
                keepAlive: true
            }
        },
        // 中医圈
        {
            path: "/chineseMedicine",
            name: 'chineseMedicine',
            component: () => import('../components/chineseMedicine/chinesemedicine'),
            meta: {
                title: "中医圈",
                auth: false,
                keepAlive: true
            }
        },
        // 中医圈详情
        {
            path: "/questionDetail",
            name: 'questionDetail',
            component: () => import('../components/chineseMedicine/questionDetail'),
            meta: {
                title: "中医圈",
                auth: false,
                keepAlive: true
            }
        },
        // 名家书籍详情
        {
            path: "/famousDetail",
            name: 'famousDetail',
            component: () => import('../components/famousDetail/famousDetail'),
            meta: {
                title: "名家书籍详情",
                auth: false,
                keepAlive: true
            }
        },
        // 扫码支付
        {
            path: "/paymentok",
            name: 'paymentok',
            component: () => import('../components/paymentok/paymentok'),
            meta: {
                title: "扫码支付",
                auth: false,
                keepAlive: true
            }
        },
        //古籍阅读
        {
            path: '/read',
            name: 'read',
            component: () => import('../components/ebook/readbook'),
            meta: {
                title: "古籍阅读",
                auth: false,
                keepAlive: true
            }
        },
        // 个人中心
        {
            path: "/personalInfo",
            name: 'personalInfo',
            component: () => import('../components/personalInfo/personalInfo'),
            meta: {
                title: "个人中心",
                auth: false,
                keepAlive: true
            }
        },
        // 我的优惠券
        {
            path: "/mycoupon",
            name: 'mycoupon',
            component: () => import('../components/personalInfo/mycoupon'),
            meta: {
                title: "个人中心",
                auth: false,
                keepAlive: true
            }
        },
        {
            path: "/imgs",
            name: 'imgs',
            component: () => import('../components/imgs/imgs'),
            meta: {
                title: "个人中心",
                auth: false,
                keepAlive: true
            }
        },
        {
            path: "/ai",
            name: 'ai',
            component: () => import('../components/ai/ai'),
            meta: {
                title: "AI中医智库",
                auth: false,
                keepAlive: true
            }
        },

    ]
})

router.beforeEach((to, from, next) => {
    console.log(to.query);
    if (from.meta.startTime) {
        let userId = localStorage.getItem('login') && localStorage.getItem('login') != "undefined" && JSON.parse(localStorage.getItem('login')).user_id ? JSON.parse(localStorage.getItem('login')).user_id : ""
        const stayTime = Math.floor((Date.now() - from.meta.startTime) / 1000);
        console.log(`从 ${from.meta.pageName} 界面离开,停留时间：${stayTime}秒`);
        let pageconfig = store.state.page_config_list
        if (from.meta.pageName == 'activityCenter') {
            //组件上传
        }
        else {
            // 界面上传 没有参数界面跳转首页,中医圈,我的,登录,搜索,高级搜索,问题详情,中医智库下载页
            if (from.meta.pageName == 'home' || from.meta.pageName == 'chineseMedicine'
                || from.meta.pageName == 'personalInfo' || from.meta.pageName == 'login'
                || from.meta.pageName == 'searchInfo' || from.meta.pageName == 'advancedSearch'
                || from.meta.pageName == 'APPDownload') {
                // 停留界面为首页时不用判断直接调用接口
                if (from.meta.pageName == 'home') {
                    let data = {
                        user_id: userId,
                        stay_time: stayTime,
                        page_id: 1,
                        platform_type: 3,
                    }
                    commonUp(data).then(res => {
                        if (res.data.status_code == 200) {
                            next()
                        } else {
                            console.log(res.data.msg)
                            next()
                        }
                    })
                } else {
                    //停留界面不为首页时则循环匹配出id
                    for (let i = 0; i < pageconfig.length; i++) {
                        if (pageconfig[i].pc_classname) {
                            if (pageconfig[i].pc_classname == '/' + from.meta.pageName) {
                                let data = {
                                    user_id: userId,
                                    stay_time: stayTime,
                                    page_id: pageconfig[i].id,
                                    platform_type: 3,
                                }
                                commonUp(data).then(res => {
                                    if (res.data.status_code == 200) {
                                        console.log(res)
                                        next()
                                    } else {
                                        console.log(res.data.msg)
                                        next()
                                    }
                                })
                            }
                        }
                    }
                }
            }
            //从基础古籍库,域外古籍库,孤本古籍库,医案库,方剂库,名医库,本草库,经穴库离开
            else if (from.meta.fullPath == '/ancientBooks?key=2-1' || from.meta.fullPath == '/ancientBooks?key=2-2' || from.meta.fullPath == '/ancientBooks?key=2-3'
                || from.meta.fullPath == '/knowledgeBooks?key=3-1' || from.meta.fullPath == '/knowledgeBooks?key=3-2' || from.meta.fullPath == '/knowledgeBooks?key=3-3'
                || from.meta.fullPath == '/knowledgeBooks?key=3-4' || from.meta.fullPath == '/knowledgeBooks?key=3-5' || from.meta.fullPath == '/personalInfo?text=myvip') {
                //停留界面不为首页时则循环匹配出id
                for (let i = 0; i < pageconfig.length; i++) {
                    if (pageconfig[i].pc_classname) {
                        if (pageconfig[i].pc_classname == from.meta.fullPath) {
                            let data = {
                                user_id: userId,
                                stay_time: stayTime,
                                page_id: pageconfig[i].id,
                                platform_type: 3,
                            }
                            commonUp(data).then(res => {
                                if (res.data.status_code == 200) {
                                    console.log(res)
                                    setTimeout(() => { next() }, 500)
                                } else {
                                    console.log(res.data.msg)
                                    next()
                                }
                            })
                        }
                    }
                }
            }
            //从古籍详情页
            else if (from.meta.pageName == 'famousDetail') {
                next()
            }
            // 从医案详情页离开
            else if (from.meta.pageName == 'knowledgeInfo') {
                console.log(from.meta.query)
                // 停留界面为首页时不用判断直接调用接口
                //停留界面不为首页时则循环匹配出id
                for (let i = 0; i < pageconfig.length; i++) {
                    if (pageconfig[i].pc_classname) {
                        if (pageconfig[i].pc_classname == '/' + from.meta.pageName) {
                            let data = {
                                user_id: userId,
                                stay_time: stayTime,
                                page_id: pageconfig[i].id,
                                detail_id: from.meta.query.case_id,
                                platform_type: 3,
                            }
                            commonUp(data).then(res => {
                                if (res.data.status_code == 200) {
                                    console.log(res)
                                    setTimeout(() => {
                                        next()
                                    }, 500)
                                } else {
                                    console.log(res.data.msg)
                                }
                            })
                        }
                    }
                }
            }
            // 从方剂详情页离开
            else if (from.meta.pageName == 'fangcategoriesInfo') {
                console.log(from.meta.query)
                // 停留界面为首页时不用判断直接调用接口
                //停留界面不为首页时则循环匹配出id
                for (let i = 0; i < pageconfig.length; i++) {
                    if (pageconfig[i].pc_classname) {
                        if (pageconfig[i].pc_classname == '/' + from.meta.pageName) {
                            let data = {
                                user_id: userId,
                                stay_time: stayTime,
                                page_id: pageconfig[i].id,
                                detail_id: from.meta.query.fangid,
                                platform_type: 3,
                            }
                            commonUp(data).then(res => {
                                if (res.data.status_code == 200) {
                                    console.log(res)
                                    setTimeout(() => {
                                        next()
                                    }, 500)
                                } else {
                                    console.log(res.data.msg)
                                }
                            })
                        }
                    }
                }
            }
            // 从名医详情页离开
            else if (from.meta.pageName == 'perscrInfo') {
                console.log(from.meta.query)
                // 停留界面为首页时不用判断直接调用接口
                //停留界面不为首页时则循环匹配出id
                for (let i = 0; i < pageconfig.length; i++) {
                    if (pageconfig[i].pc_classname) {
                        if (pageconfig[i].pc_classname == '/' + from.meta.pageName) {
                            let data = {
                                user_id: userId,
                                stay_time: stayTime,
                                page_id: pageconfig[i].id,
                                detail_id: from.meta.query.id,
                                platform_type: 3,
                            }
                            commonUp(data).then(res => {
                                if (res.data.status_code == 200) {
                                    console.log(res)
                                    setTimeout(() => {
                                        next()
                                    }, 500)
                                } else {
                                    console.log(res.data.msg)
                                }
                            })
                        }
                    }
                }
            }
            // 从本草详情页离开
            else if (from.meta.pageName == 'categoriesInfo') {
                console.log(from.meta.query)
                // 停留界面为首页时不用判断直接调用接口
                //停留界面不为首页时则循环匹配出id
                for (let i = 0; i < pageconfig.length; i++) {
                    if (pageconfig[i].pc_classname) {
                        if (pageconfig[i].pc_classname == '/' + from.meta.pageName) {
                            let data = {
                                user_id: userId,
                                stay_time: stayTime,
                                page_id: pageconfig[i].id,
                                detail_id: from.meta.query.id,
                                platform_type: 3,
                            }
                            commonUp(data).then(res => {
                                if (res.data.status_code == 200) {
                                    console.log(res)
                                    setTimeout(() => {
                                        next()
                                    }, 500)
                                } else {
                                    console.log(res.data.msg)
                                }
                            })
                        }
                    }
                }
            }
            // 从名医详情页离开
            else if (from.meta.pageName == 'jxcategoriesInfo') {
                console.log(from.meta.query)
                // 停留界面为首页时不用判断直接调用接口
                //停留界面不为首页时则循环匹配出id
                for (let i = 0; i < pageconfig.length; i++) {
                    if (pageconfig[i].pc_classname) {
                        if (pageconfig[i].pc_classname == '/' + from.meta.pageName) {
                            let data = {
                                user_id: userId,
                                stay_time: stayTime,
                                page_id: pageconfig[i].id,
                                detail_id: from.meta.query.id,
                                platform_type: 3,
                            }
                            commonUp(data).then(res => {
                                if (res.data.status_code == 200) {
                                    console.log(res)
                                    setTimeout(() => {
                                        next()
                                    }, 500)
                                } else {
                                    console.log(res.data.msg)
                                }
                            })
                        }
                    }
                }
            }
            next()

        }
    } else {
        next()
    }
    // 在进入新路由时开始计时
    to.meta.startTime = Date.now();
    to.meta.pageName = to.name; // 假设界面名称存储在路由的 name 字段中
    to.meta.fullPath = to.fullPath; // 假设界面名称存储在路由的 name 字段中
    to.meta.query = to.query; // 假设界面名称存储在路由的 name 字段中
})

export default router