import {usersApi,userJgApi} from "@/util/axiosHttp";
import store from "@/store/store";
import el from "element-ui/src/locale/lang/el";
//默认查询是否登录并且获取用户信息
function loginTypes(){
   return new Promise(function (resolve,reject){
       var user_id = localStorage.getItem('login')!="undefined"&&  localStorage.getItem('login') && JSON.parse(localStorage.getItem('login')).user_id ? JSON.parse(localStorage.getItem('login')).user_id : ""
       if(user_id!= ''){
           usersApi().then(res=>{
               resolve(res.data);
               store.commit('setnickname',res.data.data.nickname)
               store.commit('setavatar',res.data.data.avatar)
           })
           userJgApi().then(res=>{

               if (res.data.status_code == 200) {
                   localStorage.setItem('institution_name', res.data.data.institution_name)

               }
           })
       }else {
           resolve({status_code:201});
       }
   })
}
function _isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
export default {
    loginTypes,
    _isMobile
}
