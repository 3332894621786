<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {assemblyUp,commonUp} from "@/util/axiosHttp";
import {getpages} from "@/util/axiosHttp";
import store from "@/store/store";

export default {
  data() {
    return {
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      zoom: 1,
      isIOS: false,
      isPC: true,
    };
  },
  created() {
    // 判断是否为正式环境
    const isProduction = process.env.NODE_ENV === "production";
    // 重写 console.log 方法来隐藏正式环境的 console.log
    if (isProduction) {
      console.log = function () {};
    }
    getpages().then(res=>{
      console.log(res)
      store.commit("setpage_config_list",res.data.data.list);
    })
  },
  // watch: {
  //   '$route' (to, from){
  //   console.log(to.name,'进入')
  //   console.log(from,'离开')
  //    let userId = localStorage.getItem('login') && localStorage.getItem('login') != "undefined" && JSON.parse(localStorage.getItem('login')).user_id ? JSON.parse(localStorage.getItem('login')).user_id : ""
  //     //组件跳转
  //     if(to.name == 'activityCenter'){
  //
  //     }else {
  //
  //     }
  //   }
  // },
  // mounted() {
  //   var that = this;
  //   // <!--把window.onresize事件挂在到mounted函数上-->
  //   that.browserRedirect();
  //   that.zoom = that.detectZoom();
  //   window.onresize = () => {
  //     return (() => {
  //       console.log("屏幕变化");
  //       window.fullHeight = document.documentElement.clientHeight;
  //       window.fullWidth = document.documentElement.clientWidth;
  //       that.windowHeight = window.fullHeight; // 高
  //       that.windowWidth = window.fullWidth; // 宽
  //       that.browserRedirect();
  //       that.zoom = that.detectZoom();
  //     })();
  //   };
  //   var u = navigator.userAgent;
  //   if (u.indexOf("miniProgram") != -1) {
  //     console.log("小程序=》" + u);
  //   } else {
  //     console.log("网页=》" + u);
  //     //在微信网页
  //   }
  //   if (this.$commFun._isMobile()) {
  //     console.log('手机端')
  //   } else {
  //     console.log('PC端')
  //   }
  //
  //
  // },
  methods: {
    getIOSType() {
      //跳转移动端页面
      let ran = navigator.userAgent;
      let isAndroid = ran.indexOf("Android") > -1 || ran.indexOf("Linux") > -1;
      let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isAndroid) {
        this.isIOS = false;
      }
      if (isIOS) {
        this.isIOS = true;
      }
    },
    getOS() {
      var u = navigator.userAgent
      if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
        return 'windows'
      } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
        return 'macOS'
      } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
        return 'ios'
      } else if (u.match(/android/i)) {
        return 'android'
      } else if (u.match(/Ubuntu/i)) {
        return 'Ubuntu'
      } else {
        return 'other'
      }
    },
    browserRedirect() {
      const that = this;
      const sUserAgent = navigator.userAgent.toLowerCase();
      if (
          /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
              sUserAgent
          )
      ) {
        that.isPC = false;
        this.getIOSType();
      } else {
        that.isPC = true;
      }
    },
    // 方法
    detectZoom() {
      let ratio = 0;
      const screen = window.screen;
      const ua = navigator.userAgent.toLowerCase();
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
      } else if (~ua.indexOf("msie")) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      } else if (
          window.outerWidth !== undefined &&
          window.innerWidth !== undefined
      ) {
        ratio = window.outerWidth / window.innerWidth;
      }
      if (ratio) {
        ratio = Math.round(ratio * 100);
      }
      console.log(ratio);
      return ratio;
    },
  },
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

#app,
html,
body {
  width: 100%;
  height: 100%;
}

a {
  color: #333;
  text-decoration: none;
}

li {
  list-style: none;
}

</style>

