import axios from 'axios'
import apiURL from './api'
import router from '../router'
import el from "element-ui/src/locale/lang/el";

//ancient环境变量
import VUE_APP_ANCIENT_URL from './getApi/ancientApi'
let apiURLAncient = ''
//console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
    // alert("开发环境");
    apiURLAncient = 'http://proxy.jiankanghao.net:50259/api/v1'
}else {
    apiURLAncient = VUE_APP_ANCIENT_URL
}
// axios默认配置
axios.defaults.timeout = 12000;   // 超时时间
axios.defaults.baseURL = apiURL;  // 默认地址

// // 路由请求拦截
// http request 拦截器
axios.interceptors.request.use(
    config => {
        if (config.url.indexOf("zk_ji/book/read/") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("comments") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("zk_ji/book/marks") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("zk_ji/book/marks") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("book/tags") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("zk_ji/book/marks") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("book/mark") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("wq/book/read") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("book/read") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("marks") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("tags") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("mark") != -1) {
            config.baseURL = apiURLAncient
        } else if (config.url.indexOf("marks") != -1) {
            config.baseURL = apiURLAncient
        }
        return config;
    },
    error => {
    });

// // http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.status_code == 10000) {
            let myInfo = localStorage.getItem('login')?JSON.parse(localStorage.getItem('login')):{}
                let data = {
                    refresh_token:myInfo.refresh_token,
                    platform_type:'3'
                }
            axios.put('users/'+myInfo.user_id+'/access_token',data).then(res=>{
                if(res.data.status == 200){
                    let refreshdatas = {
                        access_token:res.data.data.access_token,
                        refresh_token:res.data.data.refresh_token,
                        user_id:myInfo.user_id,
                    }
                    localStorage.setItem('login',refreshdatas)
                    return response
                }
            })
        }else if(response.data.status_code == 10002){
            router.push({
                name: "login",

            })
            return
        } else {
            return response;
        }
    },
    error => {
        console.log(error.message)
        return Promise.reject(error.message)
    });
export default axios;
