import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// 绑定手机号
		bindPhoneShow:false,
		openid:"",
		unionid:"",
		nickname:"",
		avatar:"",
		// 用户信息：ID 号码 姓名 头像 openID 机构
		user_id:localStorage.user_id,
		usernumber:localStorage.usernumber,
		username:localStorage.username,
		head_img:localStorage.head_img,
		phone:localStorage.phone,
		// 机构
		mechanism:localStorage.mechanism,
		// 书籍分类信息
		// zk_book
		d_category_id:null,
		d_pid:null,
		d_ppid:null,
		d_category_name:null,
		d_p_category_name:null,
		d_pp_category_name:null,
		d_level:null,
		// zk_book
		c_category_id:null,
		c_pid:null,
		c_ppid:null,
		c_category_name:null,
		c_p_category_name:null,
		c_pp_category_name:null,
		c_level:null,
		// book
		category_id:null,
		pid:null,
		ppid:null,
		category_name:null,
		p_category_name:null,
		pp_category_name:null,
		level:null,
		// 最近搜索
		recent_word:localStorage.recent_word||'',
		// 是否时白名单
		iswhitelist:false,
		roued:null,
		access_token:localStorage.access_token,
		hashistory:false,
		iswelcome:localStorage.iswelcome,
		timestamp:localStorage.timestamp,
		login_code:localStorage.login_code,
		loginstatus:null,
		// loginstatus:localStorage.loginstatus,
		// 0、需要绑定 1、不需要
		isneed_bind:localStorage.isneed_bind,
		// 0、显示 1、不显示
		isshow_bind:localStorage.isshow_bind,
		// 0、不是 1、是
		is_twousers:localStorage.is_twousers,
		chiose_userArr:null,
		is_chiose_user:false,
		// 1、c端 2、机构 3、没有默认
		is_default_self:localStorage.is_default_self,
		is_self:localStorage.is_self,
		ip:null,
		// 跳转古籍库的key
		indexKey:'',
		login: {},
		query:{},
		page_config_list:[],
	},
	getter: {
		//获取属性的状态
	},
	mutations: {
		setbind_phone(state, data) {
			state.bindPhoneShow = data //vuex
		},
		setpage_config_list(state, data) {
			state.page_config_list = data //vuex
		},
		setlogin(state, data) {
			state.login = data //vuex
		},

		setopenid(state, data) {
			state.openid = data //vuex
		},
		setunionid(state, data) {
			state.unionid = data //vuex
		},
		setnickname(state, data) {
			state.nickname = data //vuex
		},
		setavatar(state, data) {
			state.avatar = data //vuex
		},
		// 头部组件跳转改变传入key值
		setkey(state, data) {
			// ////console.log(data+"改变key值")
			state.indexKey = data //vuex
		},



		//改变属性的状态
		setip(state, data) {
			state.ip = data //vuex
		},

		//改变属性的状态
		setinstitution_name(state, data) {
			state.institution_name = data //vuex
		},

		setuserid(state, data) {
			state.user_id = data //vuex
			localStorage.user_id = data //本地
		},
		setusernumber(state, data) {
			state.usernumber = data //vuex
			localStorage.usernumber = data //本地
		},
		setusername(state, data) {
			state.username = data //vuex
			localStorage.username = data //本地
		},
		setheadimg(state, data) {
			state.head_img = data //vuex
			localStorage.head_img = data //本地
		},
		setphone(state, data) {
			state.phone = data //vuex
			localStorage.phone = data //本地
		},
		setmechanism(state, data) {
			state.mechanism = data //vuex
			localStorage.mechanism = data //本地
		},
		// 将书详情中的category_id，pid，ppid存储
		// zk_book
		setcCategoryId(state, data) {
			state.c_category_id = data //vuex
		},
		setcPid(state,data){
			state.c_pid = data //vuex
		},
		setcPPid(state,data){
			state.c_ppid = data //vuex
		},
		setcCategoryName(state, data) {
			state.c_category_name = data //vuex
		},
		setcPCategoryName(state,data){
			state.c_p_category_name = data //vuex
		},
		setcPPCategoryName(state,data){
			state.c_pp_category_name = data //vuex
		},
		setcLevel(state,data){
			state.c_level = data //vuex
		},
		// dz_book
		setdCategoryId(state, data) {
			state.d_category_id = data //vuex
		},
		setdPid(state,data){
			state.d_pid = data //vuex
		},
		setdPPid(state,data){
			state.d_ppid = data //vuex
		},
		setdCategoryName(state, data) {
			state.d_category_name = data //vuex
		},
		setdPCategoryName(state,data){
			state.d_p_category_name = data //vuex
		},
		setdPPCategoryName(state,data){
			state.d_pp_category_name = data //vuex
		},
		setdLevel(state,data){
			state.d_level = data //vuex
		},
		// book
		setCategoryId(state, data) {
			state.category_id = data //vuex
		},
		setPid(state,data){
			state.pid = data //vuex
		},
		setPPid(state,data){
			state.ppid = data //vuex
		},
		setCategoryName(state, data) {
			state.category_name = data //vuex
		},
		setPCategoryName(state,data){
			state.p_category_name = data //vuex
		},
		setPPCategoryName(state,data){
			state.pp_category_name = data //vuex
		},
		setLevel(state,data){
			state.level = data //vuex
		},
		setchioseuser(state,data){
			state.chiose_userArr = data //vuex
		},
		setischioseuser(state,data){
			state.is_chiose_user = data //vuex
		},
		setis_default_self(state,data){
			state.is_default_self = data //vuex
			localStorage.is_default_self = data //本地
		},
		setis_self(state,data){
			if(data == true){
				state.is_self = 1 //vuex
				localStorage.is_self = 1 //本地
			}else{
				// 不是
				state.is_self = 2 //vuex
				localStorage.is_self = 2 //本地
			}
		},
		setisshowbind(state,data){
			state.isshow_bind = data //vuex
			localStorage.isshow_bind = data //本地
		},
		setneedbind(state,data){
			state.isneed_bind = data //vuex
			localStorage.isneed_bind = data //本地
		},
		settwousers(state,data){
			state.is_twousers = data //vuex
			localStorage.is_twousers = data //本地
		},
		sethashistory(state,data){
			state.hashistory = data //vuex
		},
		setwelcome(state,data){
			state.iswelcome = data //vuex
			localStorage.iswelcome = data
		},
		setwhitelist(state,data){
			state.iswhitelist = data //vuex
		},
		settoken(state,data){
			state.access_token = data //vuex
			localStorage.access_token = data //vuex
		},
		settimestamp(state,data){
			// ////console.log(data)
			state.timestamp = data //vuex
			localStorage.timestamp = data //vuex
		},
		setcode(state,data){
			// ////console.log(data)
			state.login_code = data //vuex
			localStorage.login_code = data //vuex
		},
		setloginstatus(state,data){
			// ////console.log(data)
			state.loginstatus = data //vuex
			// localStorage.loginstatus = data //vuex
		},
		// 最近搜索
		setRecentWord(state,data){
			if(state.recent_word.indexOf(data)==-1){
				if(state.recent_word==''){
					state.recent_word = state.recent_word+data
				}else{
					state.recent_word = state.recent_word+','+data
				}
				localStorage.recent_word = state.recent_word
			}
		},
		resetWord(state,data){
			state.recent_word = data
			localStorage.recent_word = state.recent_word
		},
		setroued(state,data){
			state.roued = data
		},
		setquery(state,data){
			state.query = data
		},
		deleteuserid(state,data){
			if(data==1){
				state.user_id = undefined //vuex
				localStorage.user_id = undefined
			}
		}
	},
	actions: {
		//应用属性状态
	}
})
