import Vue from 'vue';
import App from './App.vue';
import router from './router';
import DisableDevtool from 'disable-devtool';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
});
// DisableDevtool();
// ElementUI
import ElementUI from 'element-ui'; //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css';//element-ui的css
import 'jquery'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueContextMenu from 'vue-contextmenu'
Vue.use(VueContextMenu)
import VueClipboard from 'vue-clipboard2'
import Highlighter from 'web-highlighter';
window.highlighter = new Highlighter()
Vue.use(VueQuillEditor);
Vue.use(VueClipboard);
Vue.use(ElementUI) //使用elementUI
// 引入插件
import VerifySlider from 'vue-verify-slider'
// 使用插件
Vue.use(VerifySlider)
import axios from './util/http';
import VueMeta from 'vue-meta';
import store from './store/store'
import './util/rem'
//全局挂载axios
Vue.prototype.$axios = axios;
import "amfe-flexible/index.js";
Vue.use(axios);
// 公用方法
import loginTypes from '@/util/utilFunc'
Vue.prototype.$commFun = loginTypes;
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
  // 根据router自动注册
  router: router,
})
Vue.config.productionTip = false
import {returnCitySN} from './util/getip'
// 为了解决setItemEvent不立即生效，使用事件派发强制更高
store.commit('setip', returnCitySN())
router.beforeEach((to, from, next)=>{
let user_id = localStorage.getItem('login') && localStorage.getItem('login')!="undefined"&&JSON.parse(localStorage.getItem('login')).user_id?JSON.parse(localStorage.getItem('login')).user_id:""
  let accToken = localStorage.getItem('login') && localStorage.getItem('login')!="undefined" && JSON.parse(localStorage.getItem('login')).access_token ? JSON.parse(localStorage.getItem('login')).access_token : ""
  console.log(user_id);
  if(user_id != ''){

    next()
  }
    let a = window.location.href,b
    if(a.indexOf('code=') != -1){
      b = a.substring(a.indexOf('code=')).split('=')[1].split('&')
      console.log(b);
    }else {
      b = null
    }
    if(b!=null){
      let params = {
        code: b[0],
        channel: 'zk_pc',
        type:'wx',
        platform_type:'3'
      }
      axios.get('users/oauth/user', { params }).then(res=>{
        console.log(res)
        if(res.data.status_code == 200){
          store.commit("setopenid",res.data.data.openid);
          store.commit("setunionid",res.data.data.unionid);
          store.commit("setnickname",res.data.data.nickname);
          store.commit("setavatar",res.data.data.headimgurl);
          let data = {
            udid:"web",
            openid:res.data.data.openid,
            unionid:res.data.data.unionid,
            nickname:res.data.data.nickname,
            avatar:res.data.data.headimgurl,
            login_type:'wx',
            platform_type:'3',
          }
          let datas = {
            user_id:user_id,
            openid:res.data.data.openid,
            unionid:res.data.data.unionid,
            nickname:res.data.data.nickname,
            avatar:res.data.data.headimgurl,
            login_type:'wx',
            platform_type:'3',
          }
          if(a.indexOf('personalInfo')!= -1){
            axios.post('users/bind_three',  datas,{headers:{Authorization:accToken}}).then(res=>{
              next()
            })
          }else {
          axios.post('users/author_login',  data ).then(res=>{
            console.log('mian');
            if(res.data.msg == '未绑定手机号'){
              // ////console.log(1);
              store.state.bindPhoneShow = true
              next()
            }else{

              localStorage.setItem('login',JSON.stringify(res.data.data))
              if (location.href.indexOf("#") == -1 && location.href.indexOf("key") == -1) {
                //在当前页面地址加入"#"，使下次不再进入此判断
                location.href = location.href + "#";
                location.reload();
              }
              next()
            }
          })
          }
        }else{
          next()
        }
      })
    }else{
      next()
    }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
