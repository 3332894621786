import VUE_APP_USER_URL from './getApi/userApi'

let apiURLUser = ''
//console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
    // alert("开发环境");
    // apiURLUser = 'https://gaea.haiweikexin.com/api/v1'
    apiURLUser = 'http://proxy.jiankanghao.net:50205/api/v1'
}else {
    apiURLUser = VUE_APP_USER_URL
}
const apiUrl = apiURLUser;//测试域名
export default apiUrl
