import axios from "axios";
import router from "@/router";
//userApi环境变量
import VUE_APP_USER_URL from './getApi/userApi'

let apiURLUser = ''
//console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
    // alert("开发环境");
    // apiURLUser = 'https://gaea.haiweikexin.com/api/v1'
    apiURLUser = 'http://proxy.jiankanghao.net:50205/api/v1'
} else {
    apiURLUser = VUE_APP_USER_URL
}
const userApi = axios.create({
    baseURL: apiURLUser,
    timeout: 12000,
})

userApi.interceptors.request.use(
    config => {
        let time = new Date().getTime();
        console.log(config);
        // 转换为东八区时间
        const easternTime = time + (8 * 60 * 60);
        // 转换为13位精确到毫秒时间戳
        const timestamp8 = easternTime.toString();
        // console.log(timestamp8)
        switch (config.method) {
            case "get":
                //第一步对入参进行添加时间戳并根据key值排序
                config.params = {
                    ...config.params,
                    'timestamp': timestamp8,
                }
                const sortedObj1 = {};
                Object.keys(config.params)
                    .sort()
                    .forEach(key => {
                        sortedObj1[key] = config.params[key];
                    });
                config.params = sortedObj1
                //第二步对对象进行转为url入参形式的字符串
                const paramsString = Object.entries(config.params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front = timestamp8.substring(0, 8);
                let back = timestamp8.substring(timestamp8.length - 8);
                let key_val = front + back;
                //将aes_key转为可以读取的下标
                key_val = Array.from(key_val).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val = back + front;
                iv_val = Array.from(iv_val).join(",");
                let keyArray = key_val.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult = "";
                    for (let i = 0; i < keyArray.length; i++) {
                        let index = parseInt(keyArray[i]);
                        let char = token[index];
                        keyresult += char;
                    }
                    //拿到aes_iv
                    let ivArray = iv_val.split(",");
                    let ivresult = "";
                    for (let i = 0; i < ivArray.length; i++) {
                        let index = parseInt(ivArray[i]);
                        let char = token[index];
                        ivresult += char;
                    }
                    // console.log(keyresult + ',' + ivresult + ',' + paramsString);
                    const key = CryptoJS.enc.Utf8.parse(keyresult)
                    const iv = CryptoJS.enc.Utf8.parse(ivresult)
                    // 加密
                    let ciphertext = CryptoJS.AES.encrypt(paramsString, key, {
                        iv: iv,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    // console.log(ciphertext.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext.toString();


                }
                if (config.url == 'sms_code') {
                    let SIGNATURE = CryptoJS.AES.encrypt(paramsString, 'xfJgOEfkzjeavxWH', {
                        iv: 'PCeTASGYMRIRoyQz',
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    // console.log(ciphertext.toString());
                    config.headers['HW-SIGNATURE'] = SIGNATURE.toString();
                }
                break;
            case "post":
                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj2 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj2[key] = config.data[key];
                    });
                config.data = sortedObj2

                //第二步对对象进行转为url入参形式的字符串
                const paramsString1 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front1 = timestamp8.substring(0, 8);
                let back1 = timestamp8.substring(timestamp8.length - 8);
                let key_val1 = front1 + back1;
                //将aes_key转为可以读取的下标
                key_val1 = Array.from(key_val1).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val1 = back1 + front1;
                iv_val1 = Array.from(iv_val1).join(",");
                let keyArray1 = key_val1.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token1 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult1 = "";
                    for (let i = 0; i < keyArray1.length; i++) {
                        let index = parseInt(keyArray1[i]);
                        let char = token1[index];
                        keyresult1 += char;
                    }
                    //拿到aes_iv
                    let ivArray1 = iv_val1.split(",");
                    let ivresult1 = "";
                    for (let i = 0; i < ivArray1.length; i++) {
                        let index = parseInt(ivArray1[i]);
                        let char = token1[index];
                        ivresult1 += char;
                    }
                    const key1 = CryptoJS.enc.Utf8.parse(keyresult1)
                    const iv1 = CryptoJS.enc.Utf8.parse(ivresult1)
                    // 加密
                    let ciphertext1 = CryptoJS.AES.encrypt(paramsString1, key1, {
                        iv: iv1,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    config.headers['HW-SIGNATURE'] = ciphertext1.toString();
                }
                if (config.url == 'sms_code') {
                    console.log(paramsString1);
                    const key = CryptoJS.enc.Utf8.parse('xfJgOEfkzjeavxWH')
                    const iv = CryptoJS.enc.Utf8.parse('PCeTASGYMRIRoyQz')
                    let SIGNATURE = CryptoJS.AES.encrypt(paramsString1, key, {
                        iv: iv,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    console.log(SIGNATURE);
                    config.headers['HW-SIGNATURE'] = SIGNATURE.toString();
                }
                break;
            case "put":
                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj3 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj3[key] = config.data[key];
                    });
                config.data = sortedObj3

                //第二步对对象进行转为url入参形式的字符串
                const paramsString2 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front2 = timestamp8.substring(0, 8);
                let back2 = timestamp8.substring(timestamp8.length - 8);
                let key_val2 = front2 + back2;
                //将aes_key转为可以读取的下标
                key_val2 = Array.from(key_val2).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val2 = back2 + front2;
                iv_val2 = Array.from(iv_val2).join(",");
                let keyArray2 = key_val2.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token2 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult2 = "";
                    for (let i = 0; i < keyArray2.length; i++) {
                        let index = parseInt(keyArray2[i]);
                        let char = token2[index];
                        keyresult2 += char;
                    }
                    //拿到aes_iv
                    let ivArray2 = iv_val2.split(",");
                    let ivresult2 = "";
                    for (let i = 0; i < ivArray2.length; i++) {
                        let index = parseInt(ivArray2[i]);
                        let char = token2[index];
                        ivresult2 += char;
                    }
                    const key2 = CryptoJS.enc.Utf8.parse(keyresult2)
                    const iv2 = CryptoJS.enc.Utf8.parse(ivresult2)
                    // 加密
                    let ciphertext2 = CryptoJS.AES.encrypt(paramsString2, key2, {
                        iv: iv2,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    // console.log(ciphertext2.toString(),'dadadad');
                    config.headers['HW-SIGNATURE'] = ciphertext2.toString();
                }
                if (config.url == 'sms_code') {
                    let SIGNATURE = CryptoJS.AES.encrypt(paramsString, 'xfJgOEfkzjeavxWH', {
                        iv: 'PCeTASGYMRIRoyQz',
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    // console.log(ciphertext.toString());
                    config.headers['KX-SIGNATURE'] = SIGNATURE.toString();
                }
                break;
            case "delete":
                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj4 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj4[key] = config.data[key];
                    });
                config.data = sortedObj4
                //第二步对对象进行转为url入参形式的字符串
                const paramsString3 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front3 = timestamp8.substring(0, 8);
                let back3 = timestamp8.substring(timestamp8.length - 8);
                let key_val3 = front3 + back3;
                //将aes_key转为可以读取的下标
                key_val3 = Array.from(key_val3).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val3 = back3 + front3;
                iv_val3 = Array.from(iv_val3).join(",");
                let keyArray3 = key_val3.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token3 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult3 = "";
                    for (let i = 0; i < keyArray3.length; i++) {
                        let index = parseInt(keyArray3[i]);
                        let char = token3[index];
                        keyresult3 += char;
                    }
                    //拿到aes_iv
                    let ivArray3 = iv_val3.split(",");
                    let ivresult3 = "";
                    for (let i = 0; i < ivArray3.length; i++) {
                        let index = parseInt(ivArray3[i]);
                        let char = token3[index];
                        ivresult3 += char;
                    }
                    const key3 = CryptoJS.enc.Utf8.parse(keyresult3)
                    const iv3 = CryptoJS.enc.Utf8.parse(ivresult3)
                    // 加密
                    let ciphertext3 = CryptoJS.AES.encrypt(paramsString3, key3, {
                        iv: iv3,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    config.headers['HW-SIGNATURE'] = ciphertext3.toString();
                }
                break;
        }
        return config
    },
    error => {
    });

// http response 拦截器
userApi.interceptors.response.use(
    response => {
        if (response.data.status_code == 10000) {
            let myInfo = localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')) : {}
            let data = {
                refresh_token: myInfo.refresh_token,
                platform_type: '3'
            }
            axios.put('users/' + myInfo.user_id + '/access_token', data).then(res => {
                console.log(res)
                if (res.data.status_code == 200) {
                    let refreshdatas = {
                        access_token: res.data.data.access_token,
                        refresh_token: res.data.data.refresh_token,
                        user_id: myInfo.user_id,
                    }
                    localStorage.setItem('login', JSON.stringify(refreshdatas))
                    localStorage.setItem('username', JSON.stringify(refreshdatas))
                    window.location.reload();
                    return response
                } else {
                    router.push({
                        name: "login",

                    })
                }
            })
        } else {
            return response;
        }
    },
    error => {
        return Promise.reject(error.message)
    });

//ancient环境变量
import VUE_APP_ANCIENT_URL from './getApi/ancientApi'

let apiURLAncient = ''
if (process.env.NODE_ENV === "development") {
    // alert("开发环境");
    // apiURLAncient = 'https://api.zhongyigen.com/api/v1'
    apiURLAncient = 'http://proxy.jiankanghao.net:50259/api/v1'
} else {
    apiURLAncient = VUE_APP_ANCIENT_URL
}
const ancientApi = axios.create({
    baseURL: apiURLAncient,
    timeout: 12000,
})

ancientApi.interceptors.request.use(
    config => {
        let time = new Date().getTime();
        // 转换为东八区时间
        const easternTime = time + (8 * 60 * 60);
        // 转换为13位精确到毫秒时间戳
        const timestamp8 = easternTime.toString();
        switch (config.method) {
            case "get":
                //第一步对入参进行添加时间戳并根据key值排序
                config.params = {
                    ...config.params,
                    'timestamp': timestamp8,
                    'platform_type': 3,
                }
                const sortedObj1 = {};
                Object.keys(config.params)
                    .sort()
                    .forEach(key => {
                        sortedObj1[key] = config.params[key];
                    });
                config.params = sortedObj1
                //第二步对对象进行转为url入参形式的字符串
                const paramsString = Object.entries(config.params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                // console.log(paramsString,'111');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front = timestamp8.substring(0, 8);
                let back = timestamp8.substring(timestamp8.length - 8);
                let key_val = front + back;
                //将aes_key转为可以读取的下标
                key_val = Array.from(key_val).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val = back + front;
                iv_val = Array.from(iv_val).join(",");
                let keyArray = key_val.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult = "";
                    for (let i = 0; i < keyArray.length; i++) {
                        let index = parseInt(keyArray[i]);
                        let char = token[index];
                        keyresult += char;
                    }
                    //拿到aes_iv
                    let ivArray = iv_val.split(",");
                    let ivresult = "";
                    for (let i = 0; i < ivArray.length; i++) {
                        let index = parseInt(ivArray[i]);
                        let char = token[index];
                        ivresult += char;
                    }
                    const key = CryptoJS.enc.Utf8.parse(keyresult)
                    const iv = CryptoJS.enc.Utf8.parse(ivresult)
                    // console.log(paramsString);
                    // console.log(key);
                    // console.log(iv);
                    // 加密
                    let ciphertext = CryptoJS.AES.encrypt(paramsString, key, {
                        iv: iv,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    config.headers['HW-SIGNATURE'] = ciphertext.toString();
                }
                break;
            case "post":
                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                    'platform_type': 3,
                }
                const sortedObj2 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj2[key] = config.data[key];
                    });
                config.data = sortedObj2

                //第二步对对象进行转为url入参形式的字符串
                const paramsString1 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front1 = timestamp8.substring(0, 8);
                let back1 = timestamp8.substring(timestamp8.length - 8);
                let key_val1 = front1 + back1;
                //将aes_key转为可以读取的下标
                key_val1 = Array.from(key_val1).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val1 = back1 + front1;
                iv_val1 = Array.from(iv_val1).join(",");
                let keyArray1 = key_val1.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token1 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult1 = "";
                    for (let i = 0; i < keyArray1.length; i++) {
                        let index = parseInt(keyArray1[i]);
                        let char = token1[index];
                        keyresult1 += char;
                    }
                    //拿到aes_iv
                    let ivArray1 = iv_val1.split(",");
                    let ivresult1 = "";
                    for (let i = 0; i < ivArray1.length; i++) {
                        let index = parseInt(ivArray1[i]);
                        let char = token1[index];
                        ivresult1 += char;
                    }
                    const key1 = CryptoJS.enc.Utf8.parse(keyresult1)
                    const iv1 = CryptoJS.enc.Utf8.parse(ivresult1)
                    // 加密
                    let ciphertext1 = CryptoJS.AES.encrypt(paramsString1, key1, {
                        iv: iv1,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    config.headers['HW-SIGNATURE'] = ciphertext1.toString();
                }
                break;
            case "put":

                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                    'platform_type': 3,
                }
                const sortedObj3 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj3[key] = config.data[key];
                    });
                config.data = sortedObj3

                //第二步对对象进行转为url入参形式的字符串
                const paramsString2 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front2 = timestamp8.substring(0, 8);
                let back2 = timestamp8.substring(timestamp8.length - 8);
                let key_val2 = front2 + back2;
                //将aes_key转为可以读取的下标
                key_val = Array.from(key_val).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val2 = back2 + front2;
                iv_val2 = Array.from(iv_val2).join(",");
                let keyArray2 = key_val2.split(",");
                //获取token并去除Bearer
                if (config.headers.Authorization) {
                    let token2 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult2 = "";
                    for (let i = 0; i < keyArray2.length; i++) {
                        let index = parseInt(keyArray2[i]);
                        let char = token2[index];
                        keyresult2 += char;
                    }
                    //拿到aes_iv
                    let ivArray2 = iv_val2.split(",");
                    let ivresult2 = "";
                    for (let i = 0; i < ivArray2.length; i++) {
                        let index = parseInt(ivArray2[i]);
                        let char = token2[index];
                        ivresult2 += char;
                    }
                    const key2 = CryptoJS.enc.Utf8.parse(keyresult2)
                    const iv2 = CryptoJS.enc.Utf8.parse(ivresult2)
                    // 加密
                    let ciphertext2 = CryptoJS.AES.encrypt(paramsString2, key2, {
                        iv: iv2,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    config.headers['HW-SIGNATURE'] = ciphertext2.toString();
                }
                break;
            case "delete":

                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                    'platform_type': 3,
                }
                const sortedObj4 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj4[key] = config.data[key];
                    });
                config.data = sortedObj4
                //第二步对对象进行转为url入参形式的字符串
                const paramsString3 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front3 = timestamp8.substring(0, 8);
                let back3 = timestamp8.substring(timestamp8.length - 8);
                let key_val3 = front3 + back3;
                //将aes_key转为可以读取的下标
                key_val3 = Array.from(key_val3).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val3 = back3 + front3;
                iv_val3 = Array.from(iv_val3).join(",");
                let keyArray3 = key_val3.split(",");
                //获取token并去除Bearer
                if (config.headers.Authorization) {
                    let token3 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult3 = "";
                    for (let i = 0; i < keyArray3.length; i++) {
                        let index = parseInt(keyArray3[i]);
                        let char = token3[index];
                        keyresult3 += char;
                    }
                    //拿到aes_iv
                    let ivArray3 = iv_val3.split(",");
                    let ivresult3 = "";
                    for (let i = 0; i < ivArray3.length; i++) {
                        let index = parseInt(ivArray3[i]);
                        let char = token3[index];
                        ivresult3 += char;
                    }
                    const key3 = CryptoJS.enc.Utf8.parse(keyresult3)
                    const iv3 = CryptoJS.enc.Utf8.parse(ivresult3)
                    // 加密
                    let ciphertext3 = CryptoJS.AES.encrypt(paramsString3, key3, {
                        iv: iv3,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    config.headers['HW-SIGNATURE'] = ciphertext3.toString();
                }
                break;
        }
        return config
    },
    error => {
    });

// http response 拦截器
ancientApi.interceptors.response.use(
    response => {
        if (response.data.status_code == 10000) {
            let myInfo = localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')) : {}
            let data = {
                refresh_token: myInfo.refresh_token,
                platform_type: '3'
            }
            axios.put('users/' + myInfo.user_id + '/access_token', data).then(res => {
                console.log(res)
                if (res.data.status_code == 200) {
                    let refreshdatas = {
                        access_token: res.data.data.access_token,
                        refresh_token: res.data.data.refresh_token,
                        user_id: myInfo.user_id,
                    }
                    localStorage.setItem('login', JSON.stringify(refreshdatas))
                    localStorage.setItem('username', JSON.stringify(refreshdatas))
                    window.location.reload();
                    return response
                } else {
                    router.push({
                        name: "login",

                    })
                }
            })
        } else if (response.data.status_code == 10002) {
            router.push({
                name: "login",
            })
            return
        } else {
            return response;
        }
    },
    error => {
        return Promise.reject(error.message)   // 返回接口返回的错误信息
    });
//hemera环境变量
import VUE_APP_HEMERA_URL from './getApi/hemeraApi'

let apiURLHemera = ''
if (process.env.NODE_ENV === "development") {
    // alert("开发环境");
    // apiURLHemera = 'https://hemera.haiweikexin.com/api/v1'
    apiURLHemera = 'http://proxy.jiankanghao.net:50208/api/v1'
    // apiURLHemera = 'http://192.168.0.212:8303/api/v1'
} else {
    apiURLHemera = VUE_APP_HEMERA_URL
}
const hemeraApi = axios.create({
    baseURL: apiURLHemera,
    timeout: 12000,
})

hemeraApi.interceptors.request.use(
    config => {
        let time = new Date().getTime();
        // console.log(config.headers.Authorization);
        // 转换为东八区时间
        const easternTime = time + (8 * 60 * 60);
        // 转换为13位精确到毫秒时间戳
        const timestamp8 = easternTime.toString();
        //console.log(timestamp8)
        switch (config.method) {
            case "get":
                //第一步对入参进行添加时间戳并根据key值排序
                config.params = {
                    ...config.params,
                    'timestamp': timestamp8,
                }
                const sortedObj1 = {};
                Object.keys(config.params)
                    .sort()
                    .forEach(key => {
                        sortedObj1[key] = config.params[key];
                    });
                config.params = sortedObj1
                //第二步对对象进行转为url入参形式的字符串
                const paramsString = Object.entries(config.params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front = timestamp8.substring(0, 8);
                let back = timestamp8.substring(timestamp8.length - 8);
                let key_val = front + back;
                //将aes_key转为可以读取的下标
                key_val = Array.from(key_val).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val = back + front;
                iv_val = Array.from(iv_val).join(",");
                let keyArray = key_val.split(",");
                //获取token并去除Bearer
                if (config.headers.Authorization) {
                    let token = config.headers.Authorization.replace("Bearer ", '')

                    //拿到aes_key
                    let keyresult = "";
                    for (let i = 0; i < keyArray.length; i++) {
                        let index = parseInt(keyArray[i]);
                        let char = token[index];
                        keyresult += char;
                    }
                    //拿到aes_iv
                    let ivArray = iv_val.split(",");
                    let ivresult = "";
                    for (let i = 0; i < ivArray.length; i++) {
                        let index = parseInt(ivArray[i]);
                        let char = token[index];
                        ivresult += char;
                    }
                    //console.log(keyresult + ',' + ivresult + ',' + paramsString);
                    const key = CryptoJS.enc.Utf8.parse(keyresult)
                    const iv = CryptoJS.enc.Utf8.parse(ivresult)
                    // 加密
                    let ciphertext = CryptoJS.AES.encrypt(paramsString, key, {
                        iv: iv,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    //console.log(ciphertext.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext.toString();
                }
                break;
            case "post":
                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj2 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj2[key] = config.data[key];
                    });
                config.data = sortedObj2

                //第二步对对象进行转为url入参形式的字符串
                const paramsString1 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front1 = timestamp8.substring(0, 8);
                let back1 = timestamp8.substring(timestamp8.length - 8);
                let key_val1 = front1 + back1;
                //将aes_key转为可以读取的下标
                key_val1 = Array.from(key_val1).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val1 = back1 + front1;
                iv_val1 = Array.from(iv_val1).join(",");
                let keyArray1 = key_val1.split(",");
                //获取token并去除Bearer
                if (config.headers.Authorization) {
                    let token1 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult1 = "";
                    for (let i = 0; i < keyArray1.length; i++) {
                        let index = parseInt(keyArray1[i]);
                        let char = token1[index];
                        keyresult1 += char;
                    }
                    //拿到aes_iv
                    let ivArray1 = iv_val1.split(",");
                    let ivresult1 = "";
                    for (let i = 0; i < ivArray1.length; i++) {
                        let index = parseInt(ivArray1[i]);
                        let char = token1[index];
                        ivresult1 += char;
                    }
                    const key1 = CryptoJS.enc.Utf8.parse(keyresult1)
                    const iv1 = CryptoJS.enc.Utf8.parse(ivresult1)
                    // 加密
                    let ciphertext1 = CryptoJS.AES.encrypt(paramsString1, key1, {
                        iv: iv1,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    //console.log(ciphertext1.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext1.toString();
                }
                break;
            case "put":

                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj3 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj3[key] = config.data[key];
                    });
                config.data = sortedObj3

                //第二步对对象进行转为url入参形式的字符串
                const paramsString2 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front2 = timestamp8.substring(0, 8);
                let back2 = timestamp8.substring(timestamp8.length - 8);
                let key_val2 = front2 + back2;
                //将aes_key转为可以读取的下标
                key_val = Array.from(key_val).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val2 = back2 + front2;
                iv_val2 = Array.from(iv_val2).join(",");
                let keyArray2 = key_val2.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token2 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult2 = "";
                    for (let i = 0; i < keyArray2.length; i++) {
                        let index = parseInt(keyArray2[i]);
                        let char = token2[index];
                        keyresult2 += char;
                    }
                    //拿到aes_iv
                    let ivArray2 = iv_val2.split(",");
                    let ivresult2 = "";
                    for (let i = 0; i < ivArray2.length; i++) {
                        let index = parseInt(ivArray2[i]);
                        let char = token2[index];
                        ivresult2 += char;
                    }
                    const key2 = CryptoJS.enc.Utf8.parse(keyresult2)
                    const iv2 = CryptoJS.enc.Utf8.parse(ivresult2)
                    // 加密
                    let ciphertext2 = CryptoJS.AES.encrypt(paramsString2, key2, {
                        iv: iv2,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    //console.log(ciphertext2.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext2.toString();
                }
                break;
            case "delete":

                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj4 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj4[key] = config.data[key];
                    });
                config.data = sortedObj4
                //第二步对对象进行转为url入参形式的字符串
                const paramsString3 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front3 = timestamp8.substring(0, 8);
                let back3 = timestamp8.substring(timestamp8.length - 8);
                let key_val3 = front3 + back3;
                //将aes_key转为可以读取的下标
                key_val3 = Array.from(key_val3).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val3 = back3 + front3;
                iv_val3 = Array.from(iv_val3).join(",");
                let keyArray3 = key_val3.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token3 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult3 = "";
                    for (let i = 0; i < keyArray3.length; i++) {
                        let index = parseInt(keyArray3[i]);
                        let char = token3[index];
                        keyresult3 += char;
                    }
                    //拿到aes_iv
                    let ivArray3 = iv_val3.split(",");
                    let ivresult3 = "";
                    for (let i = 0; i < ivArray3.length; i++) {
                        let index = parseInt(ivArray3[i]);
                        let char = token3[index];
                        ivresult3 += char;
                    }
                    const key3 = CryptoJS.enc.Utf8.parse(keyresult3)
                    const iv3 = CryptoJS.enc.Utf8.parse(ivresult3)
                    // 加密
                    let ciphertext3 = CryptoJS.AES.encrypt(paramsString3, key3, {
                        iv: iv3,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    //console.log(ciphertext3.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext3.toString();
                }
                break;
        }
        return config
    },
    error => {
    });

// http response 拦截器
hemeraApi.interceptors.response.use(
    response => {
        ////console.log(response,'ancientApi拦截器')
        if (response.data.status_code == 10000) {
            let myInfo = localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')) : {}
            let data = {
                refresh_token: myInfo.refresh_token,
                platform_type: '3'
            }
            axios.put('users/' + myInfo.user_id + '/access_token', data).then(res => {
                console.log(res)
                if (res.data.status_code == 200) {
                    let refreshdatas = {
                        access_token: res.data.data.access_token,
                        refresh_token: res.data.data.refresh_token,
                        user_id: myInfo.user_id,
                    }
                    localStorage.setItem('login', JSON.stringify(refreshdatas))
                    localStorage.setItem('username', JSON.stringify(refreshdatas))
                    window.location.reload();
                    return response
                } else {
                    router.push({
                        name: "login",

                    })
                }
            })
        } else if (response.data.status_code == 10002) {
            router.push({
                name: "login",
            })
            return
        } else {
            return response;
        }
    },
    error => {
        //console.log(error.message)
        return Promise.reject(error.message)
    });

//oss环境变量
import VUE_APP_OSS_URL from './getApi/ossApi'

let apiURLOss = ''
////console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
    // alert("开发环境");
    // apiURLOss = 'https://api.ss.jiankanghao.net/api/v1'
    apiURLOss = 'http://proxy.jiankanghao.net:50215/api/v1'
} else {
    apiURLOss = VUE_APP_OSS_URL
}
const ossApi = axios.create({
    baseURL: apiURLOss,
    timeout: 12000,
})

// http response 拦截器
ossApi.interceptors.response.use(
    response => {
        ////console.log(response,'ossApi拦截器')
        if (response.data.status_code == 10000) {
            let myInfo = localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')) : {}
            let data = {
                refresh_token: myInfo.refresh_token,
                platform_type: '3'
            }
            axios.put('users/' + myInfo.user_id + '/access_token', data).then(res => {
                console.log(res)
                if (res.data.status_code == 200) {
                    let refreshdatas = {
                        access_token: res.data.data.access_token,
                        refresh_token: res.data.data.refresh_token,
                        user_id: myInfo.user_id,
                    }
                    localStorage.setItem('login', JSON.stringify(refreshdatas))
                    localStorage.setItem('username', JSON.stringify(refreshdatas))
                    window.location.reload();
                    return response
                } else {
                    router.push({
                        name: "login",

                    })
                }
            })
        } else if (response.data.status_code == 10002) {
            router.push({
                name: "login",

            })
            return
        } else {
            return response;
        }
    },
    error => {
        //console.log(error.message)
        return Promise.reject(error.message)
    });
//order环境变量
import VUE_APP_ORDER_URL from './getApi/orderApi'

let apiURLOrder = ''
////console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
    // alert("开发环境");
    // apiURLOrder = 'https://pontus.haiweikexin.com/api/v1'
    apiURLOrder = 'http://proxy.jiankanghao.net:50210/api/v1'
} else {
    apiURLOrder = VUE_APP_ORDER_URL
}

const orderApi = axios.create({
    baseURL: apiURLOrder,
    timeout: 12000,
})


orderApi.interceptors.request.use(
    config => {
        let time = new Date().getTime();
        //console.log(config.headers.Authorization);
        // 转换为东八区时间
        const easternTime = time + (8 * 60 * 60);
        // 转换为13位精确到毫秒时间戳
        const timestamp8 = easternTime.toString();
        //console.log(timestamp8)
        switch (config.method) {
            case "get":
                //第一步对入参进行添加时间戳并根据key值排序
                config.params = {
                    ...config.params,
                    'timestamp': timestamp8,
                }
                const sortedObj1 = {};
                Object.keys(config.params)
                    .sort()
                    .forEach(key => {
                        sortedObj1[key] = config.params[key];
                    });
                config.params = sortedObj1
                //第二步对对象进行转为url入参形式的字符串
                const paramsString = Object.entries(config.params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front = timestamp8.substring(0, 8);
                let back = timestamp8.substring(timestamp8.length - 8);
                let key_val = front + back;
                //将aes_key转为可以读取的下标
                key_val = Array.from(key_val).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val = back + front;
                iv_val = Array.from(iv_val).join(",");
                let keyArray = key_val.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult = "";
                    for (let i = 0; i < keyArray.length; i++) {
                        let index = parseInt(keyArray[i]);
                        let char = token[index];
                        keyresult += char;
                    }
                    //拿到aes_iv
                    let ivArray = iv_val.split(",");
                    let ivresult = "";
                    for (let i = 0; i < ivArray.length; i++) {
                        let index = parseInt(ivArray[i]);
                        let char = token[index];
                        ivresult += char;
                    }
                    //console.log(keyresult + ',' + ivresult + ',' + paramsString);
                    const key = CryptoJS.enc.Utf8.parse(keyresult)
                    const iv = CryptoJS.enc.Utf8.parse(ivresult)
                    // 加密
                    let ciphertext = CryptoJS.AES.encrypt(paramsString, key, {
                        iv: iv,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    //console.log(ciphertext.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext.toString();
                }
                break;
            case "post":
                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj2 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj2[key] = config.data[key];
                    });
                config.data = sortedObj2

                //第二步对对象进行转为url入参形式的字符串
                const paramsString1 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front1 = timestamp8.substring(0, 8);
                let back1 = timestamp8.substring(timestamp8.length - 8);
                let key_val1 = front1 + back1;
                //将aes_key转为可以读取的下标
                key_val1 = Array.from(key_val1).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val1 = back1 + front1;
                iv_val1 = Array.from(iv_val1).join(",");
                let keyArray1 = key_val1.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token1 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult1 = "";
                    for (let i = 0; i < keyArray1.length; i++) {
                        let index = parseInt(keyArray1[i]);
                        let char = token1[index];
                        keyresult1 += char;
                    }
                    //拿到aes_iv
                    let ivArray1 = iv_val1.split(",");
                    let ivresult1 = "";
                    for (let i = 0; i < ivArray1.length; i++) {
                        let index = parseInt(ivArray1[i]);
                        let char = token1[index];
                        ivresult1 += char;
                    }
                    const key1 = CryptoJS.enc.Utf8.parse(keyresult1)
                    const iv1 = CryptoJS.enc.Utf8.parse(ivresult1)
                    // 加密
                    let ciphertext1 = CryptoJS.AES.encrypt(paramsString1, key1, {
                        iv: iv1,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    //console.log(ciphertext1.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext1.toString();
                }
                break;
            case "put":

                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj3 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj3[key] = config.data[key];
                    });
                config.data = sortedObj3

                //第二步对对象进行转为url入参形式的字符串
                const paramsString2 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front2 = timestamp8.substring(0, 8);
                let back2 = timestamp8.substring(timestamp8.length - 8);
                let key_val2 = front2 + back2;
                //将aes_key转为可以读取的下标
                key_val = Array.from(key_val).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val2 = back2 + front2;
                iv_val2 = Array.from(iv_val2).join(",");
                let keyArray2 = key_val2.split(",");
                //获取token并去除Bearer
                if (config.headers.Authorization) {
                    let token2 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult2 = "";
                    for (let i = 0; i < keyArray2.length; i++) {
                        let index = parseInt(keyArray2[i]);
                        let char = token2[index];
                        keyresult2 += char;
                    }
                    //拿到aes_iv
                    let ivArray2 = iv_val2.split(",");
                    let ivresult2 = "";
                    for (let i = 0; i < ivArray2.length; i++) {
                        let index = parseInt(ivArray2[i]);
                        let char = token2[index];
                        ivresult2 += char;
                    }
                    const key2 = CryptoJS.enc.Utf8.parse(keyresult2)
                    const iv2 = CryptoJS.enc.Utf8.parse(ivresult2)
                    // 加密
                    let ciphertext2 = CryptoJS.AES.encrypt(paramsString2, key2, {
                        iv: iv2,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    //console.log(ciphertext2.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext2.toString();
                }
                break;
            case "delete":

                config.data = {
                    ...config.data,
                    'timestamp': timestamp8,
                }
                const sortedObj4 = {};
                Object.keys(config.data)
                    .sort()
                    .forEach(key => {
                        sortedObj4[key] = config.data[key];
                    });
                config.data = sortedObj4
                //第二步对对象进行转为url入参形式的字符串
                const paramsString3 = Object.entries(config.data)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                /***
                 *第三步取aes_key 跟 aes_iv的索引
                 * aes_key的索引为时间戳前8位后8位
                 *aes_iv的索引为时间戳后8位前8位
                 * **/
                let front3 = timestamp8.substring(0, 8);
                let back3 = timestamp8.substring(timestamp8.length - 8);
                let key_val3 = front3 + back3;
                //将aes_key转为可以读取的下标
                key_val3 = Array.from(key_val3).join(",");
                //将aes_iv转为可以读取的下标
                let iv_val3 = back3 + front3;
                iv_val3 = Array.from(iv_val3).join(",");
                let keyArray3 = key_val3.split(",");
                //获取token并去除Bearer

                if (config.headers.Authorization) {
                    let token3 = config.headers.Authorization.replace("Bearer ", '')
                    //拿到aes_key
                    let keyresult3 = "";
                    for (let i = 0; i < keyArray3.length; i++) {
                        let index = parseInt(keyArray3[i]);
                        let char = token3[index];
                        keyresult3 += char;
                    }
                    //拿到aes_iv
                    let ivArray3 = iv_val3.split(",");
                    let ivresult3 = "";
                    for (let i = 0; i < ivArray3.length; i++) {
                        let index = parseInt(ivArray3[i]);
                        let char = token3[index];
                        ivresult3 += char;
                    }
                    const key3 = CryptoJS.enc.Utf8.parse(keyresult3)
                    const iv3 = CryptoJS.enc.Utf8.parse(ivresult3)
                    // 加密
                    let ciphertext3 = CryptoJS.AES.encrypt(paramsString3, key3, {
                        iv: iv3,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    //console.log(ciphertext3.toString());
                    config.headers['HW-SIGNATURE'] = ciphertext3.toString();
                }
                break;
        }
        return config
    },
    error => {
    });

// http response 拦截器
orderApi.interceptors.response.use(
    response => {
        ////console.log(response,'orderApi拦截器')
        if (response.data.status_code == 10000) {
            let myInfo = localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')) : {}
            let data = {
                refresh_token: myInfo.refresh_token,
                platform_type: '3'
            }
            axios.put('users/' + myInfo.user_id + '/access_token', data).then(res => {
                console.log(res)
                if (res.data.status_code == 200) {
                    let refreshdatas = {
                        access_token: res.data.data.access_token,
                        refresh_token: res.data.data.refresh_token,
                        user_id: myInfo.user_id,
                    }
                    localStorage.setItem('login', JSON.stringify(refreshdatas))
                    localStorage.setItem('username', JSON.stringify(refreshdatas))
                    window.location.reload();
                    return response
                    window.location.reload();
                } else {
                    router.push({
                        name: "login",

                    })
                }
            })
        } else if (response.data.status_code == 10002) {
            router.push({
                name: "login",

            })
            return
        } else {
            return response;
        }
    },
    error => {
        //console.log(error.message)
        return Promise.reject(error.message)
    });

//data环境变量
import VUE_APP_ACQUISITION_URL from './getApi/acquisitionApi'

let apiURLAcquisition = ''
////console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
    // alert("开发环境");
    // apiURLAcquisition = 'https://bi.haiweikexin.com/api/v1/'
    apiURLAcquisition = 'http://proxy.jiankanghao.net:50289/api/v1'
} else {
    apiURLAcquisition = VUE_APP_ACQUISITION_URL
}

const acquisitionApi = axios.create({
    baseURL: apiURLAcquisition,
    timeout: 12000,
})


// acquisitionApi.interceptors.request.use(
//     config => {
//         let time = new Date().getTime();
//         //console.log(config.headers.Authorization);
// // 转换为东八区时间
//         const easternTime = time + (8 * 60 * 60);
// // 转换为13位精确到毫秒时间戳
//         const timestamp8 = easternTime.toString();
//         //console.log(timestamp8)
//         switch (config.method) {
//             case "get":
//                 //第一步对入参进行添加时间戳并根据key值排序
//                 config.params = {
//                     ...config.params,
//                     'timestamp': timestamp8,
//                 }
//                 const sortedObj1 = {};
//                 Object.keys(config.params)
//                     .sort()
//                     .forEach(key => {
//                         sortedObj1[key] = config.params[key];
//                     });
//                 config.params = sortedObj1
//                 //第二步对对象进行转为url入参形式的字符串
//                 const paramsString = Object.entries(config.params)
//                   .map(([key, value]) => `${key}=${value}`)
//                     .join('&');
//                 /***
//                  *第三步取aes_key 跟 aes_iv的索引
//                  * aes_key的索引为时间戳前8位后8位
//                  *aes_iv的索引为时间戳后8位前8位
//                  * **/
//                 let front = timestamp8.substring(0, 8);
//                 let back = timestamp8.substring(timestamp8.length - 8);
//                 let key_val = front + back;
//                 //将aes_key转为可以读取的下标
//                 key_val = Array.from(key_val).join(",");
//                 //将aes_iv转为可以读取的下标
//                 let iv_val = back + front;
//                 iv_val = Array.from(iv_val).join(",");
//                 let keyArray = key_val.split(",");
//                 //获取token并去除Bearer
//
//                 if(config.headers.Authorization) {
//                     let token = config.headers.Authorization.replace("Bearer ", '')
//                     //拿到aes_key
//                     let keyresult = "";
//                     for (let i = 0; i < keyArray.length; i++) {
//                         let index = parseInt(keyArray[i]);
//                         let char = token[index];
//                         keyresult += char;
//                     }
//                     //拿到aes_iv
//                     let ivArray = iv_val.split(",");
//                     let ivresult = "";
//                     for (let i = 0; i < ivArray.length; i++) {
//                         let index = parseInt(ivArray[i]);
//                         let char = token[index];
//                         ivresult += char;
//                     }
//                     //console.log(keyresult + ',' + ivresult + ',' + paramsString);
//                     const key = CryptoJS.enc.Utf8.parse(keyresult)
//                     const iv = CryptoJS.enc.Utf8.parse(ivresult)
//                     // 加密
//                     let ciphertext = CryptoJS.AES.encrypt(paramsString, key, {
//                         iv: iv,
//                         mode: CryptoJS.mode.CBC,
//                         padding: CryptoJS.pad.Pkcs7
//                     });
//                     //console.log(ciphertext.toString());
//                     config.headers['HW-SIGNATURE'] = ciphertext.toString();
//                 }
//                 break;
//             case "post":
//                 config.data = {
//                     ...config.data,
//                     'timestamp': timestamp8,
//                 }
//                 const sortedObj2 = {};
//                 Object.keys(config.data)
//                     .sort()
//                     .forEach(key => {
//                         sortedObj2[key] = config.data[key];
//                     });
//                 config.data = sortedObj2
//
//                 //第二步对对象进行转为url入参形式的字符串
//                 const paramsString1 = Object.entries(config.data)
//                   .map(([key, value]) => `${key}=${value}`)
//                     .join('&');
//                 /***
//                  *第三步取aes_key 跟 aes_iv的索引
//                  * aes_key的索引为时间戳前8位后8位
//                  *aes_iv的索引为时间戳后8位前8位
//                  * **/
//                 let front1 = timestamp8.substring(0, 8);
//                 let back1 = timestamp8.substring(timestamp8.length - 8);
//                 let key_val1 = front1 + back1;
//                 //将aes_key转为可以读取的下标
//                 key_val1 = Array.from(key_val1).join(",");
//                 //将aes_iv转为可以读取的下标
//                 let iv_val1 = back1 + front1;
//                 iv_val1 = Array.from(iv_val1).join(",");
//                 let keyArray1 = key_val1.split(",");
//                 //获取token并去除Bearer
//
//                 if(config.headers.Authorization) {
//                     let token1 = config.headers.Authorization.replace("Bearer ", '')
//                     //拿到aes_key
//                     let keyresult1 = "";
//                     for (let i = 0; i < keyArray1.length; i++) {
//                         let index = parseInt(keyArray1[i]);
//                         let char = token1[index];
//                         keyresult1 += char;
//                     }
//                     //拿到aes_iv
//                     let ivArray1 = iv_val1.split(",");
//                     let ivresult1 = "";
//                     for (let i = 0; i < ivArray1.length; i++) {
//                         let index = parseInt(ivArray1[i]);
//                         let char = token1[index];
//                         ivresult1 += char;
//                     }
//                     const key1 = CryptoJS.enc.Utf8.parse(keyresult1)
//                     const iv1 = CryptoJS.enc.Utf8.parse(ivresult1)
//                     // 加密
//                     let ciphertext1 = CryptoJS.AES.encrypt(paramsString1, key1, {
//                         iv: iv1,
//                         mode: CryptoJS.mode.CBC,
//                         padding: CryptoJS.pad.Pkcs7
//                     });
//                     //console.log(ciphertext1.toString());
//                     config.headers['HW-SIGNATURE'] = ciphertext1.toString();
//                 }
//                 break;
//             case "put":
//
//                 config.data = {
//                     ...config.data,
//                     'timestamp': timestamp8,
//                 }
//                 const sortedObj3 = {};
//                 Object.keys(config.data)
//                     .sort()
//                     .forEach(key => {
//                         sortedObj3[key] = config.data[key];
//                     });
//                 config.data = sortedObj3
//
//                 //第二步对对象进行转为url入参形式的字符串
//                 const paramsString2 = Object.entries(config.data)
//                   .map(([key, value]) => `${key}=${value}`)
//                     .join('&');
//                 /***
//                  *第三步取aes_key 跟 aes_iv的索引
//                  * aes_key的索引为时间戳前8位后8位
//                  *aes_iv的索引为时间戳后8位前8位
//                  * **/
//                 let front2 = timestamp8.substring(0, 8);
//                 let back2 = timestamp8.substring(timestamp8.length - 8);
//                 let key_val2 = front2 + back2;
//                 //将aes_key转为可以读取的下标
//                 key_val = Array.from(key_val).join(",");
//                 //将aes_iv转为可以读取的下标
//                 let iv_val2 = back2 + front2;
//                 iv_val2 = Array.from(iv_val2).join(",");
//                 let keyArray2 = key_val2.split(",");
//                 //获取token并去除Bearer
//                 if(config.headers.Authorization) {
//                     let token2 = config.headers.Authorization.replace("Bearer ", '')
//                     //拿到aes_key
//                     let keyresult2 = "";
//                     for (let i = 0; i < keyArray2.length; i++) {
//                         let index = parseInt(keyArray2[i]);
//                         let char = token2[index];
//                         keyresult2 += char;
//                     }
//                     //拿到aes_iv
//                     let ivArray2 = iv_val2.split(",");
//                     let ivresult2 = "";
//                     for (let i = 0; i < ivArray2.length; i++) {
//                         let index = parseInt(ivArray2[i]);
//                         let char = token2[index];
//                         ivresult2 += char;
//                     }
//                     const key2 = CryptoJS.enc.Utf8.parse(keyresult2)
//                     const iv2 = CryptoJS.enc.Utf8.parse(ivresult2)
//                     // 加密
//                     let ciphertext2 = CryptoJS.AES.encrypt(paramsString2, key2, {
//                         iv: iv2,
//                         mode: CryptoJS.mode.CBC,
//                         padding: CryptoJS.pad.Pkcs7
//                     });
//                     //console.log(ciphertext2.toString());
//                     config.headers['HW-SIGNATURE'] = ciphertext2.toString();
//                 }
//                 break;
//             case "delete":
//
//                 config.data = {
//                     ...config.data,
//                     'timestamp': timestamp8,
//                 }
//                 const sortedObj4 = {};
//                 Object.keys(config.data)
//                     .sort()
//                     .forEach(key => {
//                         sortedObj4[key] = config.data[key];
//                     });
//                 config.data = sortedObj4
//                 //第二步对对象进行转为url入参形式的字符串
//                 const paramsString3 = Object.entries(config.data)
//                   .map(([key, value]) => `${key}=${value}`)
//                     .join('&');
//                 /***
//                  *第三步取aes_key 跟 aes_iv的索引
//                  * aes_key的索引为时间戳前8位后8位
//                  *aes_iv的索引为时间戳后8位前8位
//                  * **/
//                 let front3 = timestamp8.substring(0, 8);
//                 let back3 = timestamp8.substring(timestamp8.length - 8);
//                 let key_val3= front3 + back3;
//                 //将aes_key转为可以读取的下标
//                 key_val3 = Array.from(key_val3).join(",");
//                 //将aes_iv转为可以读取的下标
//                 let iv_val3 = back3 + front3;
//                 iv_val3 = Array.from(iv_val3).join(",");
//                 let keyArray3 = key_val3.split(",");
//                 //获取token并去除Bearer
//
//                 if(config.headers.Authorization) {
//                     let token3 = config.headers.Authorization.replace("Bearer ", '')
//                     //拿到aes_key
//                     let keyresult3 = "";
//                     for (let i = 0; i < keyArray3.length; i++) {
//                         let index = parseInt(keyArray3[i]);
//                         let char = token3[index];
//                         keyresult3 += char;
//                     }
//                     //拿到aes_iv
//                     let ivArray3 = iv_val3.split(",");
//                     let ivresult3 = "";
//                     for (let i = 0; i < ivArray3.length; i++) {
//                         let index = parseInt(ivArray3[i]);
//                         let char = token3[index];
//                         ivresult3 += char;
//                     }
//                     const key3 = CryptoJS.enc.Utf8.parse(keyresult3)
//                     const iv3 = CryptoJS.enc.Utf8.parse(ivresult3)
//                     // 加密
//                     let ciphertext3 = CryptoJS.AES.encrypt(paramsString3, key3, {
//                         iv: iv3,
//                         mode: CryptoJS.mode.CBC,
//                         padding: CryptoJS.pad.Pkcs7
//                     });
//                     //console.log(ciphertext3.toString());
//                     config.headers['HW-SIGNATURE'] = ciphertext3.toString();
//                 }
//                 break;
//         }
//         return config
//     },
//     error => {
//     });

// http response 拦截器
acquisitionApi.interceptors.response.use(
    response => {
        ////console.log(response,'orderApi拦截器')
        if (response.data.status_code == 10000) {
            let myInfo = localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')) : {}
            let data = {
                refresh_token: myInfo.refresh_token,
                platform_type: '3'
            }
            axios.put('users/' + myInfo.user_id + '/access_token', data).then(res => {
                console.log(res)
                if (res.data.status_code == 200) {
                    let refreshdatas = {
                        access_token: res.data.data.access_token,
                        refresh_token: res.data.data.refresh_token,
                        user_id: myInfo.user_id,
                    }
                    localStorage.setItem('login', JSON.stringify(refreshdatas))
                    localStorage.setItem('username', JSON.stringify(refreshdatas))
                    window.location.reload();
                    return response
                    window.location.reload();
                } else {
                    router.push({
                        name: "login",

                    })
                }
            })
        } else if (response.data.status_code == 10002) {
            router.push({
                name: "login",

            })
            return
        } else {
            return response;
        }
    },
    error => {
        //console.log(error.message)
        return Promise.reject(error.message)
    });

export { userApi, ancientApi, ossApi, orderApi, hemeraApi, acquisitionApi }
